import isEmpty from 'lodash/isEmpty';

import * as actionTypes from 'store/action-types';
import { Action, PhoenixIncomingLicenseState } from 'shared/interface';
import { PER_PAGE } from 'shared/constants/constants';

const initialState: PhoenixIncomingLicenseState = {
	licenses: [],
	pagination: {
		current_page: 1,
		from: 1,
		last_page: 1,
		per_page: PER_PAGE,
		to: 1,
		total: 0
	},
	cust_pagination: {
		current_page: 1,
		from: 1,
		last_page: 1,
		per_page: PER_PAGE,
		to: 1,
		total: 0
	},
	columns: {},
	sort: {
		orderBy: 'desc',
		orderByColumn: 'id'
	},
	customers: [],
	allowRedirect: false,
	custId: '',
	closeModal: false
}

const reducer = (state: PhoenixIncomingLicenseState = initialState, action: Action): PhoenixIncomingLicenseState => {
	switch (action.type) {
		case actionTypes.FETCH_PHOENIX_INCOMING_LICENSES_INIT:
			return {
				...state,
				licenses: [],
				sort: {
					orderBy: action.payload.orderBy,
					orderByColumn: action.payload.orderByColumn,
				},
				pagination: {
					...state.pagination,
					current_page: isEmpty(action.payload.filters) ? state.pagination.current_page : action.payload.page,
					last_page: isEmpty(action.payload.filters) ? state.pagination.last_page : 1,
				}
			}
		case actionTypes.FETCH_PHOENIX_INCOMING_LICENSE_PARTNER_URLS:
			return {
				...state,
				licenses: action.payload.items
			}
		case actionTypes.FETCH_PHOENIX_INCOMING_LICENSES_SUCCESS:
			return {
				...state,
				licenses: action.payload.items,
				pagination: action.payload.pagination,
				columns: action.payload.columns,
				sort: {
					orderBy: action.payload.query.queryData.orderBy,
					orderByColumn: action.payload.query.queryData.orderByColumn,
				}
			};
		case actionTypes.SEARCH_CUSTOMER_TO_RELATE_SUCCESS:
			return {
				...state,
				customers: action.payload.items,
				cust_pagination: action.payload.pagination
			}
		case actionTypes.SEARCH_CUSTOMER_TO_RELATE_INIT:
		case 'EMPTY_CUSTOMERS':
			return {
				...state,
				customers: [],
				cust_pagination: {
					...state.cust_pagination,
					current_page: 1,
					last_page: 1,
					total: 0
				}
			}
		case actionTypes.SET_PHOENIX_LICENSE_DATE_TO_UNLIMITED_SUCCESS:
			return {
				...state,
				licenses: action.payload.licenses,
				closeModal: true
			}
		case actionTypes.RELATE_CUSTOMER_SUCCESS:
			return {
				...state,
				pagination: {
					...state.pagination,
					total: state.pagination.total - 1
				},
				licenses: action.payload.licenses,
				allowRedirect: false,
				closeModal: true
			}
		case actionTypes.RELATE_CUSTOMER_AND_REDIRECT_SUCCESS:
			return {
				...state,
				licenses: action.payload.licenses,
				pagination: {
					...state.pagination,
					total: state.pagination.total - 1
				},
				custId: action.payload.custId,
				allowRedirect: true,
				closeModal: true
			}
		case actionTypes.RESET_MODAL_STATUS:
			return {
				...state,
				custId: '',
				allowRedirect: false,
				closeModal: false
			}
		default:
			return state;
	}
}

export default reducer;
