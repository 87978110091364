import isEmpty from 'lodash/isEmpty';
import * as actionTypes from 'store/action-types';
import { ReaListState, Action } from 'shared/interface';
import { PER_PAGE } from 'shared/constants/constants';

const initialState: ReaListState = {
	reaList: [],
	pagination: {
		current_page: 1,
		from: 1,
		last_page: 1,
		per_page: PER_PAGE,
		to: 1,
		total: 0
	},
	columns: {},
	total: {},
	sort: {
		orderBy: 'desc',
		orderByColumn: 'id'
	},
	closeModal: false
}

const reducer = (state: ReaListState = initialState, action: Action): ReaListState => {
	switch (action.type) {
		case actionTypes.FETCH_REA_LIST_INIT:
			return {
				...state,
				reaList: [],
				sort: {
					orderBy: action.payload.orderBy,
					orderByColumn: action.payload.orderByColumn,
				},
				// Activate this if paging required
				// pagination: {
				// 	...state.pagination,
				// 	current_page: isEmpty(action.payload.filters) ? state.pagination.current_page : action.payload.page,
				// 	last_page: isEmpty(action.payload.filters) ? state.pagination.last_page : 1,
				// }
			}
		case actionTypes.FETCH_REA_LIST_SUCCESS:
			return {
				...state,
				reaList: action.payload.organizations,
				pagination: action.payload.pagination,
				columns: action.payload.columns,
				total: action.payload.sum,
				sort: {
					orderBy: action.payload.query.queryData.orderBy,
					orderByColumn: action.payload.query.queryData.orderByColumn,
				}
			};
		case actionTypes.RESET_MODAL_STATUS:
			return {
				...state,
				closeModal: false
			}
		default:
			return state;
	}
}

export default reducer;
