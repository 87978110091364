import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import { API_CONFIG } from 'shared/constants/constants';
import 'shared/util/localization';
import Root from './Root';
import * as serviceWorker from './serviceWorker';

let rootEl = document.getElementById('root');

if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: API_CONFIG.sentry,
		release: '0.1.0',
	});
}
// Create a reusable render method that we can call more than once
const render = (Component: React.FC) => {
	ReactDOM.render(<Component />, rootEl);
};

render(Root);

serviceWorker.unregister();
