import * as actionTypes from 'store/action-types';
import { JumpbirdPackageState, Action } from 'shared/interface';

const initialState: JumpbirdPackageState = {
	packages: [],
	closeModal: false,
}

const reducer = (state: JumpbirdPackageState = initialState, action: Action): JumpbirdPackageState => {
	switch (action.type) {
		case actionTypes.FETCH_JUMPBIRD_PACKAGES_INIT:
			return {
				...state,
				packages: []
			}
		case actionTypes.FETCH_JUMPBIRD_PACKAGES_SUCCESS:
			return {
				...state,
				packages: action.payload.packages
			};
		case 'RESET_MODAL_STATUS':
			return {
				...state,
				closeModal: false
			}
		default:
			return state;
	}
}

export default reducer;
