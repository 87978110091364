import * as actionTypes from 'store/action-types';
import { Action, OrderCodeExtensionState } from 'shared/interface';
import { PER_PAGE } from 'shared/constants/constants';

const initialState: OrderCodeExtensionState = {
	pagination: {
		current_page: 1,
		from: 1,
		last_page: 1,
		per_page: PER_PAGE,
		to: 1,
		total: 0
	},
	columns: {},
	sort: {
		orderBy: 'desc',
		orderByColumn: 'id'
	},
	filters: {}
}

const reducer = (state: OrderCodeExtensionState = initialState, action: Action): OrderCodeExtensionState => {
	switch (action.type) {
		case actionTypes.FETCH_ORDER_CODE_EXTENSION_INIT:
			return {
				...state,
				sort: {
					orderBy: action.payload.orderBy,
					orderByColumn: action.payload.orderByColumn,
				},
				pagination: {
					...state.pagination,
					current_page: action.payload.page,
				},
				filters: action.payload.filters
			}
		case actionTypes.FETCH_ORDER_CODE_EXTENSION_SUCCESS:
			return {
				...state,
				pagination: action.payload.pagination,
				columns: action.payload.columns
			};
		default:
			return state;
	}
}

export default reducer;
