import React from 'react';
import { checkPermission, AllPermissionRequired, AnyOnePermissionRequired } from 'shared/util/permission';
import { Route, RouteComponentProps, Redirect } from 'react-router';

interface Props1 extends AllPermissionRequired {
	permission: string[];
	path: string;
	exact?: boolean;
	routeKey?: any;
	component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

interface Props2 extends AnyOnePermissionRequired {
	anyOnePermission: string[];
	path: string;
	exact?: boolean;
	routeKey?: any;
	component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

/**
 * RouteManager - render routes based on permissions
 * @param permission : number of ALL permissions to check before rendering
 * @param anyOnePermission : number of ANY OF GIVEN permissions to check before rendering
 * @param path : router parameter : path
 * @param exact : router parameter : exact
 * @param component : router parameter : component
 */
const RouteManager: React.FC<Props1 | Props2> = (props) => {
	const doesHavePermission = checkPermission({
		permission: (props as Props1).permission,
		anyOnePermission: (props as Props2).anyOnePermission
	});
	if (!doesHavePermission) {
		// if user does not have permission, redirect to 404
		return <Redirect to='/404' />
	}
	return <Route key={props.routeKey} exact={!!props.exact} path={props.path} component={props.component} />
}

export default RouteManager;
