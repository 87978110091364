import * as actionTypes from 'store/action-types';
import { ConfigurationState, Action } from 'shared/interface';
import { Configuration } from '../interface/configuration.interface';

const initialState: ConfigurationState = {
	configurationList: [],
	// configuration: {} as Configuration,
	sort: {
		orderBy: 'desc',
		orderByColumn: ''
	},
	// userActionExecuted: false
}

const reducer = (state: ConfigurationState = initialState, action: Action): ConfigurationState => {
	switch (action.type) {
		case actionTypes.FETCH_CONFIGURATIONS_INIT:
			return {
				...state,
				configurationList: [],
				sort: {
					orderBy: action.payload.orderBy,
					orderByColumn: action.payload.orderByColumn,
				}
			}
		case actionTypes.FETCH_CONFIGURATIONS_SUCCESS:
			return {
				...state,
				configurationList: action.payload,
			};
		// case actionTypes.FETCH_ARCHIVED_USERS_INIT:
		// 	return {
		// 		...state,
		// 		sort: {
		// 			orderBy: action.payload.orderBy,
		// 			orderByColumn: action.payload.orderByColumn,
		// 		}
		// 	}
		// case actionTypes.FETCH_ARCHIVED_USERS_SUCCESS:
		// 	return {
		// 		...state,
		// 	};
		// case actionTypes.FETCH_USER_INIT:
		// 	return {
		// 		...state,
		// 		configuration: {} as Configuration
		// 	}
		// case actionTypes.FETCH_USER_SUCCESS:
		// 	return {
		// 		...state,
		// 		configuration: action.payload
		// 	};
		// case actionTypes.EDIT_USER_SUCCESS:
		// 	return {
		// 		...state,
		// 		userActionExecuted: true
		// 	}
		// case actionTypes.DELETE_USER_SUCCESS:
		// 	return {
		// 		...state,
		// 		userActionExecuted: true,
		// 		configurationList: action.payload
		// 	}
		// case actionTypes.ADD_USER_SUCCESS:
		// 	return {
		// 		...state,
		// 		userActionExecuted: true
		// 	}
		// case actionTypes.RESTORE_USER_SUCCESS:
		// 	return {
		// 		...state,
		// 		userActionExecuted: true
		// 	}
		case 'RESET_FLAG':
			return {
				...state,
				// userActionExecuted: false
			}
		default:
			return state;
	}
}

export default reducer;
