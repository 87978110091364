import * as actionTypes from 'store/action-types';
import { TurnoverState, Action } from 'shared/interface';

const initialState: TurnoverState = {
	turnoverList: [],
	columns: {},
	total: {},
}


const reducer = (state: TurnoverState = initialState, action: Action): TurnoverState => {
	switch (action.type) {
		case actionTypes.FETCH_TURNOVER_INIT:
			return {
				...state,
				turnoverList: [],
			}
		case actionTypes.FETCH_TURNOVER_SUCCESS:
			return {
				...state,
				turnoverList: action.payload.categories.info,
				columns: action.payload.columns,
				total: action.payload.categories.sum,
			};
		default:
			return state;
	}
}

export default reducer;
