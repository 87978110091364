import * as actionTypes from 'store/action-types';
import { PackageState, Action } from 'shared/interface';

const initialState: PackageState = {
	packages: [],
	closeModal: false
}

const reducer = (state: PackageState = initialState, action: Action): PackageState => {
	switch (action.type) {
		case actionTypes.FETCH_PACKAGES_INIT:
			return {
				...state,
				packages: []
			}
		case actionTypes.FETCH_PACKAGES_SUCCESS:
			return {
				...state,
				packages: action.payload
			};
		case actionTypes.UPDATE_PACKAGE_SUCCESS:
			return {
				...state,
				packages: action.payload.packages,
				closeModal: true
			}
		case actionTypes.CHANGE_PACKAGE_STATUS_SUCCESS:
			return {
				...state,
				packages: action.payload.packages,
				closeModal: true
			}
		case 'RESET_MODAL_STATUS':
			return {
				...state,
				closeModal: false
			}
		default:
			return state;
	}
}

export default reducer;
