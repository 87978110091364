import isEmpty from 'lodash/isEmpty';
import * as actionTypes from 'store/action-types';
import { InventoriesState, Action } from 'shared/interface';
import {PER_PAGE} from "../../../../shared/constants/constants";

const initialState: InventoriesState = {
	inventories: [],
	pagination: {
		current_page: 1,
		from: 1,
		last_page: 1,
		per_page: PER_PAGE,
		to: 1,
		total: 0
	},
	columns: {},
	sort: {
		orderBy: 'asc',
		orderByColumn: 'name'
	},
	filters: {},
	closeModal:false
}

const reducer = (state: InventoriesState = initialState, action: Action): InventoriesState => {
	switch (action.type) {
		case actionTypes.FETCH_INVENTORIES_INIT:
			return {
				...state,
				inventories: [],
				sort: {
					orderBy: action.payload.orderBy,
					orderByColumn: action.payload.orderByColumn,
				},
				pagination: {
					...state.pagination,
					current_page: isEmpty(action.payload.filters) ? state.pagination.current_page : action.payload.page,
					last_page: isEmpty(action.payload.filters) ? state.pagination.last_page : 1,
				},
			};
		case actionTypes.FETCH_INVENTORIES_SUCCESS:
			return {
				...state,
				inventories: action.payload.items,
				pagination: action.payload.pagination,
				columns: action.payload.columns,
			};
		case actionTypes.CHANGE_INVENTORY_STATUS_SUCCESS:
			return {
				...state,
				inventories: action.payload.inventories,
				closeModal:true
			};
		case 'RESET_MODAL_STATUS':
			return {
				...state,
				closeModal:false
			}
		default:
			return state;
	}
}

export default reducer;
