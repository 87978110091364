import isEmpty from 'lodash/isEmpty';
import * as actionTypes from 'store/action-types';
import { InvoiceGroupState, Action } from 'shared/interface';
import { PER_PAGE } from 'shared/constants/constants';

const initialState: InvoiceGroupState = {
	invoiceGroupList: [],
	pagination: {
		current_page: 1,
		from: 1,
		last_page: 1,
		per_page: PER_PAGE,
		to: 1,
		total: 0
	},
	columns: {},
	sort: {
		orderBy: 'desc',
		orderByColumn: 'id'
	},
	closeModal: false
}

const reducer = (state: InvoiceGroupState = initialState, action: Action): InvoiceGroupState => {
	switch (action.type) {
		case actionTypes.FETCH_INVOICE_GROUP_INIT:
			return {
				...state,
				invoiceGroupList: [],
				sort: {
					orderBy: action.payload.orderBy,
					orderByColumn: action.payload.orderByColumn,
				},
				pagination: {
					...state.pagination,
					current_page: isEmpty(action.payload.filters) ? state.pagination.current_page : action.payload.page,
					last_page: isEmpty(action.payload.filters) ? state.pagination.last_page : 1,
				}
			}
		case actionTypes.FETCH_INVOICE_GROUP_SUCCESS:
			return {
				...state,
				invoiceGroupList: action.payload.items,
				pagination: action.payload.pagination,
				columns: action.payload.columns,
				sort: {
					orderBy: action.payload.query.queryData.orderBy,
					orderByColumn: action.payload.query.queryData.orderByColumn,
				}
			};
		case actionTypes.UPDATE_INVOICE_GROUP:
			return {
				...state,
				invoiceGroupList: action.payload.data,
				pagination: { ...state.pagination, current_page: 1 },
				sort: {
					orderBy: action.payload.query.queryData.orderBy,
					orderByColumn: action.payload.query.queryData.orderByColumn,
				}
			};
		case actionTypes.RESET_MODAL_STATUS:
			return {
				...state,
				closeModal: false
			}
		default:
			return state;
	}
}

export default reducer;
