import * as actionTypes from 'store/action-types';
import { RolesState, Action } from 'shared/interface';

const initialState: RolesState = {
	roles: [],
	closeModal: false
}

const reducer = (state: RolesState = initialState, action: Action): RolesState => {
	switch (action.type) {
		case actionTypes.FETCH_ROLES_INIT:
			return {
				...state,
				roles: []
			}
		case actionTypes.FETCH_ROLES_SUCCESS:
			return {
				...state,
				roles: action.payload
			};
		case actionTypes.ADD_ROLE_SUCCESS:
			state.roles.push(action.payload.role);
			return {
				...state,
				roles: state.roles,
				closeModal: true
			}
		case actionTypes.REMOVE_ROLE_SUCCESS:
			return {
				...state,
				roles: action.payload.roles,
				closeModal: true
			}
		case actionTypes.EDIT_ROLE_SUCCESS:
			return {
				...state,
				roles: action.payload.roles,
				closeModal: true
			}
		case 'RESET_MODAL_STATUS':
			return {
				...state,
				closeModal: false
			}
		default:
			return state;
	}
}

export default reducer;
