import * as actionTypes from 'store/action-types';
import { CredentialState, Action } from 'shared/interface';

const initialState: CredentialState = {
	credentials: [],
	closeModal: false
}

const reducer = (state: CredentialState = initialState, action: Action): CredentialState => {
	switch (action.type) {
		case actionTypes.FETCH_CREDENTIAL_INIT:
			return {
				...state,
				credentials: []
			}
		case actionTypes.FETCH_CREDENTIAL_SUCCESS:
			return {
				...state,
				credentials: action.payload
			};
		case actionTypes.ADD_CREDENTIAL_SUCCESS:
			return {
				...state,
				credentials: action.payload.credentials,
				closeModal: true
			}
		case actionTypes.UPDATE_CREDENTIAL_SUCCESS:
			return {
				...state,
				credentials: action.payload.credentials,
				closeModal: true
			}
		case 'RESET_MODAL_STATUS':
			return {
				...state,
				closeModal: false
			}
		default:
			return state;
	}
}

export default reducer;
